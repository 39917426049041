import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatToolbarModule } from '@angular/material/toolbar';

import { NavbarContainerComponent } from './components/navbar-container/navbar-container.component';
import { RouterModule } from '@angular/router';
import { MaterialExampleModule } from '../material.module';

@NgModule({
  declarations: [NavbarContainerComponent],
  imports: [CommonModule, MaterialExampleModule, RouterModule],
})
export class LayoutModule {}
