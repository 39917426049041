<header class="spai-header">
  <mat-toolbar>
    <span>Jobs</span>
    <span class="example-spacer"></span>
    <button
      class="spai-button dropdown"
      [title]="userName"
      #t="matMenuTrigger"
      [matMenuTriggerFor]="userSettingMenu"
      aria-haspopup="true"
      [attr.aria-expanded]="t.menuOpen">
      <span>
        <img src="https://picsum.photos/200" alt="" class="spai-icon-picture" />
      </span>
      {{ userName }}
    </button>

    <mat-menu #userSettingMenu="matMenu">
      <button mat-menu-item (click)="logOutClick()">Log out</button>
    </mat-menu>
  </mat-toolbar>
</header>

<div style="margin-top: 64px">
  <nav class="spai-nav" aria-label="Project navigation">
    <ul class="spai-nav-list">
      <li class="spai-nav-item">
        <a class="spai-nav-link p-0 mb-3" routerLink="/jobs/list">
          <span class="nav-active ms-3">Jobs</span>
        </a>
        <ul class="spai-sub-nav">
          <li class="spai-nav-item">
            <a class="spai-nav-link p-0 mb-3" routerLink="/jobs/request">
              <span class="nav-active ms-3">Request</span>
            </a>
          </li>
          <li class="spai-nav-item">
            <a class="spai-nav-link p-0 mb-3" routerLink="/jobs/execution">
              <span class="nav-active ms-3">Execution</span>
            </a>
          </li>
          <li class="spai-nav-item">
            <a class="spai-nav-link p-0 mb-3" routerLink="/jobs/images">
              <span class="nav-active ms-3">Images</span>
            </a>
          </li>
          <li class="spai-nav-item">
            <a class="spai-nav-link p-0 mb-3" routerLink="/jobs/runners">
              <span class="nav-active ms-3">Runners</span>
            </a>
          </li>
          <li class="spai-nav-item">
            <a class="spai-nav-link p-0 mb-3" routerLink="/jobs/definitions">
              <span class="nav-active ms-3">Definitions</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="spai-nav-item">
        <a class="spai-nav-link p-0 mb-3" routerLink="/tenants">
          <span class="nav-active ms-3">Tenants</span>
        </a>
      </li>
    </ul>
  </nav>

  <main class="spai-main-content-wrapper">
    <div class="spai-main-content">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
