import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'spai-navbar-container',
  templateUrl: './navbar-container.component.html',
  styleUrls: ['./navbar-container.component.scss'],
})
export class NavbarContainerComponent implements OnInit {
  userName: string;

  constructor(private router: Router, private authService: AuthService) {
    this.userName = authService.getUserName();
  }

  ngOnInit(): void {}

  logOutClick(): void {
    this.authService.clearAuthData();
    this.router.navigate(['login']);
  }
}
