import { Injectable } from "@angular/core";

import { LocalStorage, MemoryStorage } from "./abstractStorage";

export const AUTH_STORAGE_KEY = {
  accessToken: 'spai-access-token',
  refreshToken: 'spai-op-data',
  accessTokenExpiredIn: 'spai-a_in',
  refreshTokenExpiredIn: 'spai-r_in',
  user: 'spai-user',
  impersonatedUserRoles: 'spai-impersonated-user-roles',
};

@Injectable({providedIn: 'root'})
export class AuthStorage {

  constructor(
    private storage: LocalStorage,
    private memoryStorage: MemoryStorage,
  ) {}


  get payload(): any {
    return getJwtPayload(this.getAccessToken() || '');
  }


  getAccessToken(): string | null {
    return this.memoryStorage.getItem(AUTH_STORAGE_KEY.accessToken) || null;
  }

  setAccessToken(accessToken: string): void {
    this.memoryStorage.setItem(AUTH_STORAGE_KEY.accessToken, accessToken);
  }

  clearAuthData(): void {
    this.storage.removeItem(AUTH_STORAGE_KEY.refreshToken);
    this.memoryStorage.removeItem(AUTH_STORAGE_KEY.accessTokenExpiredIn);
    this.memoryStorage.removeItem(AUTH_STORAGE_KEY.accessToken);
    this.storage.removeItem(AUTH_STORAGE_KEY.refreshTokenExpiredIn);
  }

  setRefreshToken(refreshToken: string): void {
    const encodedRefreshToken = btoa(refreshToken);
    const data = {
      _o: encodedRefreshToken.substring(0, encodedRefreshToken.length / 2),
      _p: encodedRefreshToken.substring(encodedRefreshToken.length / 2)
    };
    this.storage.setItem(AUTH_STORAGE_KEY.refreshToken, JSON.stringify(data));
  }

  getRefreshToken(): string | null {
    let data = this.storage.getItem(AUTH_STORAGE_KEY.refreshToken) || null;

    if (data) {
      const parsedData = JSON.parse(data);
      data = atob(parsedData._o + parsedData._p);
    }

    return data;
  }

  setAccessExpirationDate(expirationDate: string): void {
    this.memoryStorage.setItem(AUTH_STORAGE_KEY.accessTokenExpiredIn, expirationDate);
  }

  setRefreshExpirationDate(expirationDate: string): void {
    this.storage.setItem(
      AUTH_STORAGE_KEY.refreshTokenExpiredIn,
      expirationDate
    );
  }

  getAccessTokenExpiredIn(): string | null {
    return this.memoryStorage.getItem(AUTH_STORAGE_KEY.accessTokenExpiredIn) || null;
  }

  getRefreshTokenExpiredIn(): string | null {
    return this.storage.getItem(AUTH_STORAGE_KEY.refreshTokenExpiredIn) || null;
  }

}

function getJwtPayload(token: string): any {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
