import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { NotificationComponent } from './notification.component';
import { Notification } from './notification.interface';
import { NotificationsService } from './notifications.service';

@Component({
  selector: 'app-notifications',
  imports:[NotificationComponent, CommonModule],
  template: `
    <div class="spai-notifications-wrapper">
      <app-notification
          *ngFor="let a of notifications"
          [item]="a"
          [timeOut]="timeOut"
          [isDeleted]="!!a.isDeleted"
          >
      </app-notification>
    </div>
    `,
    standalone: true
})
export class NotificationsComponent implements OnInit, OnDestroy {
  notifications: Notification[] = [];

  // Autoclose timeout
  timeOut = 5000;

  // global listener - in our case lister for global click event
  private globalListenFunc: Function | undefined;

  // Event emitter listener
  private listener: any;

  // Minimal time before closing
  private minTimeout = 500;

  constructor(
    private service: NotificationsService,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    // Listen for changes in the service
    this.listener = this.service.getChangeEmitter()
      .subscribe(item => {
        switch (item.command) {
          case 'cleanAll':
            this.notifications = [];
            break;

          case 'set':
            if (item.add)  {
              this.add(item.notification);
            } else {
              this.defaultBehavior(item);
            }
            break;

          default:
            this.defaultBehavior(item);
            break;
        }
      });
  }

  // Default behavior on event
  defaultBehavior(value: any) {
    this.notifications.splice(this.notifications.indexOf(value.notification), 1);

    if (this.notifications.length === 0) {
      this.clearGlobalListener();
    }
  }

  // Add the new notification to the notification array
  add(item: any) {
    item.createdOn = new Date();
    item.isDeleted = false;
    this.notifications.splice(0, 0, item);

    if (!this.globalListenFunc) {
      // We cache the function "listenGlobal" returns
      this.globalListenFunc = this.renderer.listen('document', 'click', () => {
        const now = +new Date();

        this.notifications.forEach((notification) => {
          if (now - +notification.createdOn! > this.minTimeout) {
            notification.isDeleted = true;
          }
        });

        this.clearGlobalListener();
      });
    }
  }

  /**
   * Remove global listener and reset another options
   */
  clearGlobalListener() {
    // remove global listener
    if (this.globalListenFunc) {
      this.globalListenFunc();
    }

    this.globalListenFunc = undefined;
  }

  /**
   * On destroy clear memory to avoid memory leaks
   */
  ngOnDestroy() {
    if (this.listener) {
      this.listener.unsubscribe();
    }
  }
}
