export abstract class AbstractStorage implements Storage {
  readonly length: number = 0;

  abstract clear(): void;

  abstract getItem(key: string): string | null;

  abstract key(index: number): string | null;

  abstract removeItem(key: string): void;

  abstract setItem(key: string, value: string): void;
}

export abstract class MemoryStorage extends AbstractStorage {}
export abstract class LocalStorage extends AbstractStorage { }
