import { HttpClient } from '@angular/common/http';
import {
  catchError,
  mergeMap,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { AppConfig } from '../models';
import { JWK, TokenResponse } from '../models/tokenResponse.model';

import { SignInModel } from '../models/sign-in.model';
import { AuthStorage } from '../storage/auth-storage.service';
import { Injectable } from '@angular/core';
import { JwkValidator } from './jwk-validator';


@Injectable({providedIn: 'root'})
export class AuthService {
  private readonly basePath: string;
  private readonly hostUrl: string;

  private readonly MAP = {
    accessToken: "_g",
    refreshToken: "_q",
    accessTokenExpireTime: "_px",
    refreshTokenExpireTime: "_lk",
    twoFactorToken: "_vq",
  };

  constructor(
    private config: AppConfig,
    private httpClient: HttpClient,
    private authStorage: AuthStorage,
    private jwkValidator: JwkValidator
  ) {
    this.hostUrl = `${this.config.api}${this.config.version}`;
    this.basePath = `${this.hostUrl}/user`;
  }

  get isAuthenticated(): boolean {
    return !!this.authStorage.getAccessToken() && !this._isTokenExpiered();
  }

  signIn(sigInModel: SignInModel) {
    const url = this.basePath + '/login';

    return this.httpClient.post<TokenResponse>(url, sigInModel)
    .pipe(
      mergeMap((response: any) => {
        if (response["token"]
        || (!response[this.MAP.accessToken] && response[this.MAP.twoFactorToken])) {
          return of(response);
        }

        return this.validateAndStoreJwt(response);
      }),
      catchError((response) => {
        return throwError(() => response);
      })
    );
  }

  refreshAndValidateToken(): Observable<TokenResponse> {
    const refreshToken = this.authStorage.getRefreshToken();
    if (!refreshToken) {
      return throwError('Refresh token not found');
    }

    return this._refreshToken(refreshToken).pipe(
      mergeMap((response) => {
        return this.jwkValidator.validateToken(response["_g"]).pipe(
          mergeMap((res) => {
            if (res) {
              this._storeToken(response);
              return of(response);
            } else {
              return throwError('Token validation failed');
            }
          })
        );
      }),
      catchError((response) => {
        return throwError(() => response);
      })
    );
  }

  clearAuthData(): void {
    this.authStorage.clearAuthData();
  }
  getUserName(): string {
    return this.authStorage.payload['sub'];
  }

  checkAuthenticationDate() {
    if (this.authStorage.getRefreshToken()) {

      return this.refreshAndValidateToken()
                 .pipe(
                   tap(res => this._storeToken(res)),
                   catchError(() => {
                     this.clearAuthData();
                     return of(false);
                   })
                 );
    } else {
      this.clearAuthData();
      return of(false);
    }
  }

  getUserRoles(): Array<string> {
    return this.authStorage.payload['t3'] as Array<string> || [];
  }

  private _isTokenExpiered(): boolean {
    return Number(this.authStorage.getAccessTokenExpiredIn()) < new Date().getTime();
  }

  private _storeToken(tokenResponse: TokenResponse): void {
    //@ts-ignore
    this.authStorage.setAccessToken(tokenResponse[this.MAP.accessToken]);
    //@ts-ignore
    this.authStorage.setRefreshToken(tokenResponse[this.MAP.refreshToken]);
    this.authStorage.setAccessExpirationDate(
     //@ts-ignore
      String(tokenResponse[this.MAP.accessTokenExpireTime])
    );
    this.authStorage.setRefreshExpirationDate(
    //@ts-ignore
      String(tokenResponse[this.MAP.refreshTokenExpireTime])
    );
  }

  private _refreshToken(refreshToken: string): Observable<TokenResponse> {
    const url = this.basePath + '/refresh';

    return this.httpClient.post<TokenResponse>(url, { refreshToken });
  }

  private validateAndStoreJwt(tokenResponse: TokenResponse): Observable<TokenResponse> {
    const accessToken = tokenResponse[this.MAP.accessToken as keyof TokenResponse] as string;
    return this.jwkValidator.validateToken(accessToken).pipe(
      switchMap((isValid) => {
        if (isValid) {
          this._storeToken(tokenResponse);
          return of(tokenResponse);
        }

        throw new Error("Invalid token");
      })
    );
  }
}
