

<app-popup
*ngIf="message"
[hasOverlay]="true"
[isFixed]="true"
[hasNoCorner]="true"
[title]="'New messages'"
(onClose)="onCloseMessage(message.id)"
(click)="$event.stopPropagation();"
>
<div class="app-popup__wrap popup-message">
  <h1 class="popup-message__header">
    {{ message.headline }}
  </h1>

  <div class="popup-message__content">
    {{ message.text }}
  </div>
</div>
</app-popup>

<app-notifications></app-notifications>

<router-outlet></router-outlet>
