import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { NotificationsService } from 'src/app/notifications/notifications.service';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {
  constructor(
    private notification: NotificationsService,
    private authService: AuthService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    const roles = route.data['allowedRoles'] as Array<string>;

    if (
      roles &&
      !roles.some(r => this.authService.getUserRoles().includes(r))
    ) {
      this.notification.info('Invalid user role');
      return this.router.createUrlTree(['login']);
    }

    return true;
  }
}
