import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';

/**
 * By default renders a small popup (more like popover).
 * Supports:
 * - < and > arrows in the header via isPrevNextMode
 * - large popup with [isLarge]="true"
 * - add dark background with hasOverlay
 * - position in center with [isFixed]="true"
 * - change header color with .app-popup--warning, .app-popup--success and .app-popup--warning
 */
@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  standalone: true
})
export class PopupComponent implements AfterViewInit, OnDestroy {

  /**
   * Class that is added to body when popup with overlay is open.
   * @type {string}
   */
  bodyClass = 'app-popup--open';

  /**
   * Hide close btn
   * @type {boolean}
   */
  @Input()
  isHideCloseBtn = false;

  /**
   * Popup title text
   * @type {string}
   */
  @Input()
  title = 'Title';

  /**
   * Hides close btn
   * Shows prev next "arrows"
   * @type {boolean}
   */
  @Input()
  @HostBinding('class.app-popup--prev-next-mode')
  isPrevNextMode = false;

  /**
   * Sets specific css class to host element.
   * @type {boolean}
   */
  @Input()
  @HostBinding('class.app-popup--fixed')
  isFixed = false;

  /**
   * Sets specific css class to host element.
   * @type {boolean}
   */
  @Input()
  @HostBinding('class.app-popup--without-corner')
  hasNoCorner = false;

  /**
   * Sets specific css class to host element.
   * @type {boolean}
   */
  @Input()
  @HostBinding('class.app-popup--large')
  isLarge = false;

  /**
   * Sets specific css class to host element.
   * @type {boolean}
   */
  @Input()
  @HostBinding('class.app-popup--medium')
  isMedium = false;

  /**
   * Sets specific css class to host element.
   * @type {boolean}
   */
  @Input()
  @HostBinding('class.app-popup--loading')
  isLoading = false;

  /**
   * Set to true to add a black backdrop.
   * By default enables closeOnOverlayClick
   * @type {boolean}
   */
  @Input()
  @HostBinding('class.app-popup--overlay')
  hasOverlay = false;

  /**
   * Define default host element class
   * @type {boolean}
   */
  @HostBinding('class.app-popup')
  defaultClass = true;

  /**
   * Emits on close event to parent
   * @type {EventEmitter<boolean>}
   */
  @Output()
  onClose = new EventEmitter<boolean>();

  /**
   * Emits within ngAfterViewInit lifecycle hook getBoundingClientRect values
   * @type {EventEmitter}
   */
  @Output()
  onBoundingRectChange = new EventEmitter();

  /**
   * Emits on next(true) or prev(false) event
   * @type {EventEmitter<boolean>}
   */
  @Output()
  onNextPrev = new EventEmitter<boolean>();

  constructor(
    public elementRef: ElementRef,
    private renderer: Renderer2,
  ) { }

  ngAfterViewInit() {
    if (this.hasOverlay) {
      this.renderer.addClass(document.body, this.bodyClass);
    }

    // wait layout stabilization (height, bounding rect)
    setTimeout(() => {
      const rect = this.elementRef.nativeElement.getBoundingClientRect();

      this.onBoundingRectChange.emit(rect);
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, this.bodyClass);
  }

  /**
   * Closes popup:
   * - stops event propagation
   * - emits close event
   * @param ev
   */
  close(ev: MouseEvent) {
    ev.stopPropagation();
    this.onClose.emit(true);
    this.renderer.removeClass(document.body, this.bodyClass);
  }

  /**
   * Handles previous button click
   * Emits event to parent
   * @param {MouseEvent} ev
   */
  prevClicked(ev: MouseEvent) {
    ev.stopPropagation();
    this.onNextPrev.emit(false);
  }

  /**
   * Handles next button click
   * Emits event to parent
   * @param {MouseEvent} ev
   */
  nextClicked(ev: MouseEvent) {
    ev.stopPropagation();
    this.onNextPrev.emit(true);
  }

  /**
   * If closeOnOverlayClick is on, closes the popup on overlay click.
   * @param {MouseEvent} event
   */
  @HostListener('click', ['$event'])
  onPopupClicked(event: MouseEvent) {
    if (this.hasOverlay && this.elementRef.nativeElement === event.target) {
      this.close(event);
    }
  }
}
