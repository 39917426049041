import { APP_INITIALIZER } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

export function refreshToken(authService: AuthService): () => Promise<unknown> {
  return () =>
    new Promise((resolve, reject) => {
      authService.checkAuthenticationDate().subscribe({
        next: () => resolve(''),
        error: error => reject(error),
      });
    });
}

export const INIT_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: refreshToken,
    multi: true,
    deps: [AuthService],
  },
];
