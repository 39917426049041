<div class="app-popup__container">
  <header class="app-popup__header">
   <!-- <app-icon *ngIf="!isHideCloseBtn" name="cross2" class="app-popup__btn-left" (click)="close($event)"></app-icon> -->

    <!--  <app-icon *ngIf="isPrevNextMode" name="chevron-left" class="app-popup__btn-left" (click)="prevClicked($event)"></app-icon>
    <app-icon *ngIf="isPrevNextMode" name="chevron-right" class="app-popup__btn-right" (click)="nextClicked($event)"></app-icon> -->

    <span [innerHtml]="title"></span>
  </header>

  <section class="app-popup__content-wrap">
    <ng-content></ng-content>
  </section>
</div>
