import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppConfig } from './app/core/models';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

fetch(`assets/ui-config.json?t=${new Date().getTime()}`)
  .then(data => data.json())
  .then(appConfig => {
    platformBrowserDynamic([
      {
        provide: AppConfig,
        useValue: appConfig,
      },
    ])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });
