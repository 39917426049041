import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationEvent } from './notification-event.interface';
import { Notification } from './notification.interface';

@Injectable({providedIn: 'root'})
export class NotificationsService {
  private emitter$ = new Subject<NotificationEvent>();

  set(notification: Notification, to: boolean) {
    this.emitter$.next({ command: 'set', notification: notification, add: to });
    return notification;

  }

  getChangeEmitter() {
    return this.emitter$;
  }

  // Access methods
  info(title: string) {
    return this.set({ title: title, type: 'info' }, true);
  }

  // Remove all notifications method
  remove() {
    this.emitter$.next({ command: 'cleanAll' });
  }

  /**
   * For the current moment, it's a simple wrapper around browser confirm implementation.
   * @param {string} message
   * @returns {Promise<any>}
   */
  confirm(message: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      if (confirm(message)) {
        resolve(undefined);
      } else {
        reject();
      }
    });
  }
}
