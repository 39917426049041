import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { AuthStorage } from '../storage/auth-storage.service';

export const AUTH_HEADER_KEY = 'Authorization';
export const AUTH_RESPONSE_MAP = {
  accessToken: '_g',
  refreshToken: '_q',
  accessTokenExpireTime: '_px',
  refreshTokenExpireTime: '_lk',
};


@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private authStorage: AuthStorage,
    private authService: AuthService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.url.indexOf('assets') >= 0 ||
      req.url.indexOf('auth') >= 0 ||
      req.url.indexOf('user/refresh') >= 0 ||
      req.url.indexOf('login') >= 0
    ) {
      return next.handle(req);
    }

    if (!this.authService.isAuthenticated) {
      return this.refreshToken(req, next);
    }

    return next.handle(this.addAuthorizationHeader(req)).pipe(
      catchError((error: any) => {
        if (error.statusText === 'Unauthorized' && error.status === 401) {
         // this.authService.clearAuthData();
        }
        return throwError(() => error);
      })
    );
  }

  private addAuthorizationHeader(request: HttpRequest<any>): HttpRequest<any> {
    if (!!this.authStorage.getAccessToken()) {
      return request.clone({ setHeaders: this.getAccessHeader() });
    }

    return request;
  }

  private refreshToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshAndValidateToken().pipe(
        switchMap((res: any) => {
          this.isRefreshing = false;
          //@ts-ignore
          this.refreshTokenSubject.next(res[AUTH_RESPONSE_MAP.accessToken]);
          return next.handle(this.addAuthorizationHeader(request));
        }),
        catchError(error => {
          return of(error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => !!token),
        take(1),
        switchMap(() =>
          next.handle(this.addAuthorizationHeader(request)).pipe(
            catchError(error => {
              const returnUrl = this.router.routerState.snapshot;
              this.router.navigate(['/login'], {
                queryParams: { returnUrl: returnUrl },
              });
              return of(error);
            })
          )
        )
      );
    }
  }

  private getAccessHeader(): { [name: string]: string } {
    return { [AUTH_HEADER_KEY]: `Bearer ${this.authStorage.getAccessToken()}` };
  }
}
