import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, Subject } from "rxjs";
import { AppConfig } from "../models";



const NAME = "RSASSA-PKCS1-v1_5";
const ALGORITHM = {
  name: NAME,
  hash: { name: "SHA-256" },
};

@Injectable({
  providedIn: "root",
})
export class JwkValidator {
  constructor(private http: HttpClient, private config: AppConfig) {}

  validateToken(token: string): Observable<boolean> {
    const publicJwkUrl = `${this.config.api}${this.config.version}/auth/_g`;
    const subject = new Subject<boolean>();

    this.http.get(publicJwkUrl).subscribe(async (jwk: JsonWebKey) => {
      try {
        const header = this.getPart(token, 0);
        const payload = this.getPart(token, 1);
        const signature = this.getSignaturePart(token);

        const signatureIsValid = await this.verifySignature(
          header,
          payload,
          signature,
          jwk
        );

        if (signatureIsValid) {
          subject.next(true);
          subject.complete();
        } else {
          subject.next(false);
          subject.error("Invalid signature");
          subject.complete();
        }
      } catch (error) {
        subject.error(error);
      }
    });

    return subject.asObservable();
  }

  private getPart(token: string, index: 0 | 1): any {
    const parts = token.split(".");
    if (parts.length >= 3) {
      return parts[index];
    }

    return '';
  }

  private getSignaturePart(token: string): string {
    const parts = token.split(".");

    if (parts.length >= 3) {
      return parts[2];
    }
    return '';
  }

  private async verifySignature(
    header: any,
    payload: any,
    signature: string,
    jwk: JsonWebKey
  ): Promise<boolean> {
    const data = `${header}.${payload}`;
    const encoder = new TextEncoder();

    return crypto.subtle
      .importKey("jwk", jwk, ALGORITHM, true, ["sign"])
      .then((cryptoKey) => {
        return crypto.subtle
          .sign(NAME, cryptoKey, encoder.encode(data))
          .then((sign) => {
            const isEqual = new Uint8Array(
              this.base64UrlToUint8Array(signature)
            ).every((val, i) => val === new Uint8Array(sign)[i]);

            return isEqual;
          });
      })
      .catch(() => false);
  }

  private base64UrlToUint8Array(base64Url: string): Uint8Array {
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const rawData = window.atob(base64);
    const buffer = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      buffer[i] = rawData.charCodeAt(i);
    }
    return buffer;
  }
}
